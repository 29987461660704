<template>
    <ts-loading-banner :loading="loading">
        <div class="tw-space-y-2">
            <div class="row" v-if="$whois.admin()">
                <div class="col-md-12 tw-space-y-1">
                    <label class="required">{{
                        $t("productCategory.shopName")
                    }}</label>
                    <a-select
                        v-model="model.shop_id"
                        :options="shopList"
                        @change="onShopChange"
                        showSearch
                        :filterOption="
                            (input, option) =>
                                option.componentOptions.children[0].text
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                        "
                        class="tw-w-full"
                        :placeholder="$t('productCategory.shopName')"
                    ></a-select>
                    <div
                        class="tw-text-red-600 tw-text-xs"
                        v-if="errors.has('shop_id')"
                    >
                        {{ errors.first("shop_id") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 tw-space-y-1">
                    <label class="required">{{
                        $t("productCategory.categoryCode")
                    }}</label>
                    <input
                        v-model="model.category_code"
                        type="text"
                        class="form-control"
                        readonly
                        :placeholder="$t('productCategory.categoryCode')"
                    />
                    <div
                        class="tw-text-red-600 tw-text-xs"
                        v-if="errors.has('category_code')"
                    >
                        {{ errors.first("category_code") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 tw-space-y-1">
                    <label>{{ $t("productCategory.nameKh") }}</label>
                    <input
                        v-model="model.category_name_kh"
                        type="text"
                        class="form-control"
                        :placeholder="$t('productCategory.nameKh')"
                    />
                    <div
                        class="tw-text-red-600 tw-text-xs"
                        v-if="errors.has('category_name_kh')"
                    >
                        {{ errors.first("category_name_kh") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 tw-space-y-1">
                    <label class="required">{{
                        $t("productCategory.nameEn")
                    }}</label>
                    <input
                        v-model="model.category_name_en"
                        type="text"
                        class="form-control"
                        :placeholder="$t('productCategory.nameEn')"
                    />
                    <div
                        class="tw-text-red-600 tw-text-xs"
                        v-if="errors.has('category_name_en')"
                    >
                        {{ errors.first("category_name_en") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 tw-flex tw-justify-end tw-space-x-3">
                    <ts-button
                        class="mr-2"
                        @click.prevent="() => $emit('cancel')"
                    >
                        {{ $t("cancel") }}
                    </ts-button>
                    <ts-button
                        v-if="!isUpdate"
                        outline
                        color="info"
                        @click.prevent="onSaveAddNew"
                        >{{ $t("saveAddNew") }}</ts-button
                    >
                    <ts-button
                        v-if="!isUpdate"
                        color="primary"
                        @click.prevent="onSave"
                        >{{ $t("save") }}</ts-button
                    >
                    <ts-button
                        v-if="isUpdate"
                        color="primary"
                        @click.prevent="onUpdate"
                        >{{ $t("update") }}</ts-button
                    >
                </div>
            </div>
        </div>
    </ts-loading-banner>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { Errors } from "form-backend-validation";
import { isEmpty } from "lodash";

export default {
    name: "productCategoryForm",
    data() {
        return {
            errors: new Errors(),
            loading: false,
            model: {
                shop_id: undefined,
                category_code: "",
                category_name_kh: "",
                category_name_en: ""
            }
        };
    },
    computed: {
        ...mapState("inventory/productCategory", ["formModels", "edit_data"]),
        isUpdate() {
            return !isEmpty(this.edit_data);
        },
        shopList() {
            if (!this.formModels.shop) return [];

            return this.formModels.shop.map(el => ({
                value: el.shop_id,
                label: el.shop_name
            }));
        }
    },
    created() {
        this.fetchFormView({ params: {}, index: undefined });
    },
    methods: {
        ...mapActions("inventory/productCategory", [
            "getFormViewData",
            "store",
            "update"
        ]),
        fetchFormView({ params, index }) {
            this.loading = true;
            this.getFormViewData({ params: params, index: index })
                .then(response => {
                    if (response.data.categoryCode.length) {
                        this.model.category_code =
                            response.data.categoryCode[0].code;
                    }
                    this.setEditData();
                })
                .catch(err => {
                    this.$notify({ type: "error", text: err.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        onSave() {
            this.errors = new Errors();
            this.loading = true;
            this.store(this.model)
                .then(response => {
                    this.$notify({ type: "success", text: response.message });
                    this.$emit("cancel");
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        onSaveAddNew() {
            this.errors = new Errors();
            this.loading = true;
            this.store(this.model)
                .then(response => {
                    this.$notify({ type: "success", text: response.message });
                    this.clearInput();
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        onUpdate() {
            this.errors = new Errors();
            this.loading = true;
            this.update({
                id: this.model.category_id,
                data: this.model
            })
                .then(response => {
                    this.$notify({ type: "success", text: response.message });
                    this.$emit("cancel");
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        clearInput() {
            this.model.category_id = "";
            this.model.category_name_kh = "";
            this.model.category_name_en = "";
            this.onShopChange(this.model.shop_id);
        },
        setEditData() {
            if (!isEmpty(this.edit_data)) {
                this.model.shop_id = this.edit_data.shop_id;
                this.model.category_id = this.edit_data.category_id;
                this.model.category_code = this.edit_data.category_code;
                this.model.category_name_kh = this.edit_data.category_name_kh;
                this.model.category_name_en = this.edit_data.category_name_en;
            }
        },
        onShopChange(value) {
            this.fetchFormView({
                index: "categoryCode",
                params: {
                    shop_id: value,
                    fnName: "categoryCode"
                }
            });
        }
    }
};
</script>
